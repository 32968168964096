<template>
<!-- Footer -->
<div class="container p-3" />
<footer class="bg-dark text-center text-white">
  <div class="container p-2">
    <section>
      <a class="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/ColognePatriots" role="button" title="Facebook"><i class="fab fa-facebook-f"></i></a>
      <a class="btn btn-outline-light btn-floating m-1" href="https://colognepatriots.kadermanager.de" role="button" title="Kadermanager"><i class="fab fa-kaggle"></i></a>
      <a class="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/colognepatriots" role="button" title="Instagram"><i class="fab fa-instagram"></i></a>
    </section>
  </div>
  <div class="text-center p-2 btn-outline-light">
    © 2024 Cologne Patriots Hockey
  </div>
</footer>
</template>
