<template>
  <div class="container">
    <div class="row align-items-center d-flex">
      <div class="col align-items-center">
        <router-link to="/" class="nav-link">
          <img src="../assets/img/logo_small.png">
        </router-link>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <nav class="navbar navbar-expand-sm" role="navigation">
          <ul class="navbar-nav mx-auto text-uppercase">
            <li class="nav-item"><router-link to="/team" class="nav-link">Team</router-link></li>
            <li class="nav-item"><router-link to="/dates" class="nav-link">Termine</router-link></li>
            <li class="nav-item"><router-link to="/gallery" class="nav-link">Galerie</router-link></li>
            <li class="nav-item"><router-link to="/about" class="nav-link">Impressum</router-link></li>
        </ul>
      </nav>
    </div>
  </div>
  </div>
  <div class="bg-light">&nbsp;</div>
  <div class="bg-dark">&nbsp;</div>
</template>
