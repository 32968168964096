<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Cologne Patriots</h1>
        <p>
          Als eine Interessengemeinschaft im Kölner Eishockey gestartet
          verbindet uns seit 2013 der Spaß am wohl schönsten Sport.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <img src="../assets/img/stock_skates.jpg" class="img-fluid" />
      </div>
      <div class="col ms-auto text-start">
        <p>
          Wir trainieren wöchentlich in der Eishalle in Bergisch
          Gladbach/Bensberg. Eis haben wir dort Montags von 21:15 - 22:45 Uhr.
          Zusätzlich haben wir auch Sommereis im Icedome Troisdorf.
        </p>
        <p>
          Wenn Du Interesse an einem Gasttraining hast bitte über eine der
          Social Media Kanäle bei uns melden. <br />
          Gastspieler zahlen einen Beitrag in Höhe von 20€ welcher vor
          Trainingsbeginn an unseren Kassenwart zu entrichten ist. Eine
          vollständige und intakte Ausrüstung ist zu deiner eigenen Sicherheit
          absolute Pflicht. Spieler ohne Ausrüstung werden nicht zum Training
          zugelassen.
        </p>
      </div>
    </div>
  </div>
</template>

<script></script>
