<template>
  <app-header />
  <main>
    <router-view />
  </main>
  <app-footer />
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@800&display=swap');

@import './assets/styles/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

</style>
